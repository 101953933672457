import React, { useEffect, useRef, useState } from "react";
import { Button, Container, Row, Spinner } from "react-bootstrap";
// import { useDB } from "../../../contexts/DBContext";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { FaTimes, FaInstagram } from "react-icons/fa";
import styles from "./ArtistLayout.module.scss";
import { LazyLoadImage } from "react-lazy-load-image-component";
import InstagramComp from "../../comp/InstagramComp";

export const ArtistLayout = () => {
  const modalRef = useRef();
  const { slug } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [artistPhotos, setArtistPhotos] = useState([]);
  const artistContentRef = useRef(null);
  const portfolioRef = useRef(null);
  const [openImg, setOpenImg] = useState(null);
  const [staticImages, setStaticImages] = useState([]);
  const [artistInfo, setArtistInfo] = useState({});

  useEffect(() => {
    if (slug != null) {
      let images;
      switch (slug) {
        case "rafal":
          images = importAll(rafalImages);
          break;
        case "magda":
          images = importAll(magdaImages);
          break;
        case "MrRzymski":
          images = importAll(jarekImages);
          break;
        case "lena":
          images = importAll(lenaImages);
          break;
        default:
          images = [];
      }
      setStaticImages(images);
      setArtistPhotos(images);
    }
  }, [slug]);

  useEffect(() => {
    if (slug != null) {
      let info;
      switch (slug) {
        case "rafal":
          info = {
            name: "Rafal",
            description:
              "Mr. Greg Tattoo Studio is a well-established tattoo studio located in the heart of Edinburgh. Famous for its high-quality standards, the studio bonds a team of skilled tattoo artists dedicated to delivering great designs and customer experiences. Whether you're seeking a custom piece or exploring a variety of styles, Mr. Greg Tattoo Studio ensures that each tattoo is a unique work of art.",
            src: require("../../../images/resized4.jpg"),
            placeholderSrc: require("../../../images/transparent.png"),
            alt: "Rafal piercing and tattoos portrait image",
            instaId: "rudytattoopiercing",
          };
          break;
        case "lena":
          info = {
            name: "Lena",
            description:
              "Lena is a talented tattoo artist known for her realistic color tattoos and her ability to blend multiple styles. Her style combines realism with elements of abstract and watercolor designs, creating tattoos that are both vibrant and unique. Lena's attention to detail and use of color result in lifelike and visually stunning pieces, making her the most sought-after artist for those seeking distinctive and personalized body art.",
            src: require("../../../images/resized23.jpg"),
            placeholderSrc: require("../../../images/transparent.png"),
            alt: "Lena tattoos portrait image",
            instaId: "lenatattooarts",
          };
          break;
        case "magda":
          info = {
            name: "Magda",
            description:
              "Magda is a renowned tattoo artist known for her dotwork and black graphical designs. Her work often blends detailed dot patterns with bold, modern aesthetics, frequently incorporating elements of nature, abstract forms, and sacred geometry. This unique combination makes her tattoos highly wanted for their precision and visual impact.",
            src: require("../../../images/resized13.jpg"),
            placeholderSrc: require("../../../images/transparent.png"),
            alt: "Magda tattoos portrait image",
            instaId: "young.goat.ink",
          };
          break;
        case "MrRzymski":
          info = {
            name: "Mr Rzymski",
            description:
              "Mr Rzymski, a legendary tattoo artist with 30 years of experience, specializes exclusively in black and gray realistic tattoos. His unparalleled craftsmanship and ability to create lifelike, intricate designs have cemented his status as a true master in the tattoo industry. With a keen eye for detail and a dedication to perfection, Mr Rzymski transforms every tattoo into a breathtaking work of art.",
            src: require("../../../images/resized3.jpg"),
            placeholderSrc: require("../../../images/transparent.png"),
            alt: "Mr Rzymski tattoos portrait image",
            instaId: "mrrzymski",
          };
          break;
        default:
          info = {};
      }
      setArtistInfo(info);
    }
  }, [slug]);

  const rafalImages = require.context(
    `../../../images/rafal`,
    false,
    /\.(png|jpe?g|svg)$/,
  );
  const lenaImages = require.context(
    `../../../images/lena`,
    false,
    /\.(png|jpe?g|svg)$/,
  );
  const jarekImages = require.context(
    `../../../images/jarek`,
    false,
    /\.(png|jpe?g|svg)$/,
  );
  const magdaImages = require.context(
    `../../../images/magda`,
    false,
    /\.(png|jpe?g|svg)$/,
  );
  const importAll = (r) => {
    let images = [];
    r.keys().forEach((item, index) => {
      images.push(r(item));
    });
    return images;
  };

  function scrollToSection(id) {
    const el = document.getElementById(id);
    el?.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "nearest",
    });
  }

  // console.log(openImg);

  return (
    <>
      <Container className={styles.artistWrap}>
        <Row className={styles.goBack}>
          {openImg ? null : (
            <FaTimes
              onClick={() => {
                setOpenImg("");
                navigate("/", { state: { from: location } });
              }}
            />
          )}
        </Row>
        <Row className={styles.innerWrap}>
          <div className={styles.artistContent} ref={artistContentRef}>
            <div className={styles.left}>
              <div className={styles.content}>
                <h2>{artistInfo.name}</h2>
                <p>{artistInfo.description}</p>
                <Link
                  to={`https://www.instagram.com/${artistInfo.instaId}/`}
                  target="_blank"
                  onClick={() => {}}
                >
                  View on Instagram
                </Link>
              </div>
            </div>
            <div className={styles.right}>
              <h2>{artistInfo.name}</h2>
              <div className={styles.imgWrap}>
                <LazyLoadImage
                  src={artistInfo.src}
                  placeholderSrc={artistInfo.placeholderSrc}
                  alt={artistInfo.alt}
                  className="img-lazy"
                />
              </div>
            </div>
          </div>
          <div className={styles.portfolioWrap} ref={portfolioRef}>
            <InstagramComp
              className={styles.artistPortfolio}
              instagram={[]}
              drive={staticImages}
              openImg={openImg}
              setOpenImg={setOpenImg}
            />
          </div>
        </Row>
      </Container>
    </>
  );
};
