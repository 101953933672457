import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

const TRACKING_ID = process.env.REACT_APP_FIREBASE_MEASUREMENT_ID;
ReactGA.initialize(TRACKING_ID);

export function Gtag() {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname,
      title: document.title,
    });
  }, [location.pathname]);

  return null;
}
